import { AxiosResponse } from 'axios';
import queryString from 'query-string';

import { NotificationType } from 'models/notification';
import { getRequest, patchRequest } from 'utils/HttpRequest/http-request-utils';

/**
 * notificationType is hard-coded to UI type only
 * notification sorting is also set to descending by default in back-end
 * fromDate is set to 7 days ago by default
 */
export async function fetchNotifications(
  enterpriseId: number
): Promise<AxiosResponse<any, any>> {
  const path = queryString.stringifyUrl({
    url: 'notifications/v2',
    query: {
      enterpriseId: enterpriseId.toString(),
      notificationType: NotificationType.UI.toString()
    }
  });

  const response = await getRequest(path);

  // TODO: Use APIResponseParsed type from Cibo epic. For now, let error bubble-up
  return response;
}

export async function patchNotification(
  notificationId: number,
  status: number
): Promise<AxiosResponse<any, any>> {
  const path = `notifications/v2/${notificationId}`;
  const data = { status };

  const response = await patchRequest(path, data);

  // TODO: Use APIResponseParsed type from Cibo epic. For now, let error bubble-up
  return response;
}
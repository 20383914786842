import axios from 'axios';
import queryString from 'query-string';
import { call } from 'redux-saga/effects';

import { IPCameraCapabilities, IPCameraEditableFields, IPCameraPTZPresetEditableFields, PTZControl } from 'models/ipCamera';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'utils/redux-saga-requests';

// ================================================
// TYPES
// ================================================
export type FetchIPCameraPhotosParams = {
  page: number;
  pageSize: number;
  order: 'ASC' | 'DESC';
  startDate?: string;
  endDate?: string;
};

// ================================================
// REQUESTS
// ================================================
export function* fetchIPCamerasBySite(enterpriseId: number, siteId: number) {
  const enterpriseIdFilter = `landwatchIntegration.enterpriseId::eq::${enterpriseId}`;
  const siteIdFilter = `siteId::eq::${siteId}`;

  const path = queryString.stringifyUrl({
    url: 'ip-camera',
    query: {
      filter: [enterpriseIdFilter, siteIdFilter],
      join: 'preview'
    }
  });

  try {
    const data = yield call(getRequest, path);

    return data;
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
  }
}

export function* fetchIPCameraById(id: number) {
  const path = `ip-camera/${id}`;
  try {
    const data = yield call(getRequest, path);

    return data;
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
  }
}

export function* fetchIPCameraPhotos(
  ipCameraId: number,
  params: FetchIPCameraPhotosParams
) {
  const path = `ip-cameras/${ipCameraId}/images`;
  const response = yield call(getRequest, path, { params });
  return response;
}

export function* fetchIPCameraONVIFStateById(id: number) {
  const path = `ip-camera/${id}/onvif-state`;
  const response = yield call(getRequest, path);
  return response;
}

export function* fetchIPCameraCapabilities(id: number, fields?: (keyof IPCameraCapabilities)[]) {
  const path = `ip-camera/${id}/capabilities`;
  const response = yield call(getRequest, path, { params: { fields } });
  return response;
}

export function* takeIPCameraPreview(id: number) {
  const path = `ip-camera/${id}/take-preview`;
  const response = yield call(postRequest, path, undefined);
  return response;
}

export function* sendIPCameraControl(id: number, control: PTZControl) {
  const path = `ip-camera/${id}/control`;

  try {
    yield call(postRequest, path, control);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
  }
};

export function* fetchIPCameraEvents(
  ipCameraId: number,
  startDate: string,
  endDate: string
) {
  const queryParams = queryString.stringify({
    startDate,
    endDate
  });

  const path = `ip-camera/${ipCameraId}/events?${queryParams}`;

  try {
    const response = yield call(getRequest, path);

    return response;
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
  }
}

export function* editIPCamera(id: number, values: IPCameraEditableFields) {
  const path = `ip-camera/${id}`;
  return yield call(patchRequest, path, values);
}

export function* deleteIPCamera(id: number) {
  const path = `ip-camera/${id}`;
  return yield call(deleteRequest, path);
}

export function* takeIPCameraSnapshot(ipCameraId: number) {
  const path = `ip-camera/${ipCameraId}/take-snapshot`;
  return yield call(postRequest, path, undefined);
}

export function* fetchIPCameraPTZPresets(enterpriseId: number, ipCameraId: number) {
  const enterpriseIdFilter = `landwatchIntegration.enterpriseId::eq::${enterpriseId}`;

  const path = queryString.stringifyUrl({
    url: `ip-camera/${ipCameraId}/presets`,
    query: {
      filter: [enterpriseIdFilter]
    }
  });
  return yield call(getRequest, path);
}

export function* createIPCameraPTZPreset(ipCameraId: number) {
  const path = `ip-camera/${ipCameraId}/presets/create`;
  return yield call(postRequest, path, undefined);
}

export function* updateIPCameraPTZPreset(ipCameraId: number, presetId: number, values: IPCameraPTZPresetEditableFields) {
  const path = `ip-camera/${ipCameraId}/presets/${presetId}`;
  return yield call(patchRequest, path, values);
}

export function* deleteIPCameraPTZPreset(ipCameraId: number, presetId: number) {
  const path = `ip-camera/${ipCameraId}/presets/${presetId}`;
  return yield call(deleteRequest, path);
}

export function* activateIPCameraPTZPreset(ipCameraId: number, presetId: number) {
  const path = `ip-camera/${ipCameraId}/presets/${presetId}/activate`;
  return yield call(postRequest, path, undefined);
}
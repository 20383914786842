import { call } from 'redux-saga/effects';

import { SortOrder } from 'constants/list';
import { EnterpriseId } from 'models/enterprise';
import { CiboSubscription } from 'redux/modules/backOfficeCiboSubscriptions/types';
import { CiboLocation } from 'redux/modules/ciboLocations/types';
import { getRequest, patchRequest, postRequest } from 'utils/redux-saga-requests';

// ==============================
// TYPES
// ==============================
type FetchAllCiboSubscriptionsParams = {
  enterpriseId: EnterpriseId;
  order: SortOrder;
};

type FetchAllCiboLocationsParams = {
  enterpriseId: EnterpriseId;
};

export interface CreateCiboSubscriptionDTO extends Pick<CiboSubscription, 'enterpriseId' | 'numberOfLocations'> { }

// ==============================
// REQUESTS
// ==============================
/** Must fetch 'all' to count the total zones purchased */
export function* fetchAllCiboSubscriptions(
  params: FetchAllCiboSubscriptionsParams
): Generator<any, CiboSubscription[], any> {
  const response = yield call(getRequest, 'integrations/cibolabs', { params });
  return response;
}

/** Must fetch 'all' to count the total zones placed */
export function* fetchAllCiboLocations(params: FetchAllCiboLocationsParams): Generator<any, CiboLocation[], any> {
  const response = yield call(getRequest, 'integrations/cibolabs/locations', { params });
  return response;
}

export function* addCiboSubscription(data: CreateCiboSubscriptionDTO): Generator<any, CiboSubscription, any> {
  const response = yield call(postRequest, 'integrations/cibolabs', data);
  return response;
}

export function* cancelCiboSubscription(subscriptionId: number): Generator<any, CiboSubscription, any> {
  const response = yield call(patchRequest, `integrations/cibolabs/${subscriptionId}`, { status: 'cancelled' });
  return response;
}
import { call } from 'redux-saga/effects';

import { ExternalDeviceNotificationPolicy } from 'models/notificationPolicy';
import { getRequest, putRequest } from 'utils/redux-saga-requests';

export function* fetchNotificationPolicies(siteId: number) {
  const path = `notification-policy/site/${siteId}`;
  const response = yield call(getRequest, path);
  return response;
}

export function* fetchExternalDeviceNotificationPolicies(siteId: number) {
  const path = `notification-policy/site/${siteId}/external`;
  const response = yield call(getRequest, path);

  return response;
}

export function* editExternalDeviceNotificationPolicies(
  siteId: number,
  externalNotificationPolicies: ExternalDeviceNotificationPolicy[]
) {
  const path = `notification-policy/site/${siteId}/external`;
  const response = yield call(putRequest, path, externalNotificationPolicies);

  return response;
}
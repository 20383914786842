import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import { CreateCiboSubscriptionDTO } from 'clients/cibo';
import { SortOrder } from 'constants/list';
import { EnterpriseId } from 'models/enterprise';

import ActionTypes from './constants';
import { CiboSubscription } from './types';

// ==============================
// SUBSCRIPTIONS
// ==============================
/** Must fetch 'all' to count the total zones purchased */
export const fetchAllBackOfficeCiboSubscriptionsLatestFirst = (enterpriseId: EnterpriseId) =>
  action(ActionTypes.FETCH_BACK_OFFICE_CIBO_SUBSCRIPTIONS_REQUEST, { enterpriseId, order: SortOrder.DESC });

export const fetchBackOfficeCiboSubscriptionsSuccess = (response: AxiosResponse) =>
  action(ActionTypes.FETCH_BACK_OFFICE_CIBO_SUBSCRIPTIONS_SUCCESS, { response });

export const fetchBackOfficeCiboSubscriptionsFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.FETCH_BACK_OFFICE_CIBO_SUBSCRIPTIONS_FAILURE, { message, error }, undefined, true);

export const setBackOfficeCiboSubscriptions = (enterpriseId: EnterpriseId, subscriptions: CiboSubscription[]) =>
  action(ActionTypes.SET_BACK_OFFICE_CIBO_SUBSCRIPTIONS, { enterpriseId, subscriptions });

export const addBackOfficeCiboSubscription = (data: CreateCiboSubscriptionDTO) =>
  action(ActionTypes.ADD_BACK_OFFICE_CIBO_SUBSCRIPTION_REQUEST, data);

export const addBackOfficeCiboSubscriptionSuccess = (response: AxiosResponse) =>
  action(ActionTypes.ADD_BACK_OFFICE_CIBO_SUBSCRIPTION_SUCCESS, { response });

export const addBackOfficeCiboSubscriptionFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.ADD_BACK_OFFICE_CIBO_SUBSCRIPTION_FAILURE, { message, error }, undefined, true);

export const cancelBackOfficeCiboSubscription = (subscriptionId: number) =>
  action(ActionTypes.CANCEL_BACK_OFFICE_CIBO_SUBSCRIPTION_REQUEST, { subscriptionId });

export const cancelBackOfficeCiboSubscriptionSuccess = (response: AxiosResponse) =>
  action(ActionTypes.CANCEL_BACK_OFFICE_CIBO_SUBSCRIPTION_SUCCESS, { response });

export const cancelBackOfficeCiboSubscriptionFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.CANCEL_BACK_OFFICE_CIBO_SUBSCRIPTION_FAILURE, { message, error }, undefined, true);

export const openBackOfficeCiboCancellationModal = (subscriptionId: number) =>
  action(ActionTypes.OPEN_BACK_OFFICE_CIBO_CANCELLATION_MODAL, { subscriptionId });

export const closeBackOfficeCiboCancellationModal = (subscriptionId: number) =>
  action(ActionTypes.CLOSE_BACK_OFFICE_CIBO_CANCELLATION_MODAL, { subscriptionId });

export const openBackOfficeCiboPlacedZonesModal = (subscriptionId: number, numberOfLocationsToRemove: number) =>
  action(ActionTypes.OPEN_BACK_OFFICE_CIBO_PLACED_ZONES_MODAL, { subscriptionId, numberOfLocationsToRemove });

export const closeBackOfficeCiboPlacedZonesModal = (subscriptionId: number) =>
  action(ActionTypes.CLOSE_BACK_OFFICE_CIBO_PLACED_ZONES_MODAL, { subscriptionId });

// ==============================
// LOCATIONS (aka PASTURE ZONES)
// ==============================
/** Must fetch 'all' to count the total zones placed */
export const fetchAllBackOfficeCiboLocations = (enterpriseId: EnterpriseId) =>
  action(ActionTypes.FETCH_BACK_OFFICE_CIBO_LOCATIONS_REQUEST, { enterpriseId });

export const fetchBackOfficeCiboLocationsSuccess = (response: AxiosResponse) =>
  action(ActionTypes.FETCH_BACK_OFFICE_CIBO_LOCATIONS_SUCCESS, { response });

export const fetchBackOfficeCiboLocationsFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.FETCH_BACK_OFFICE_CIBO_LOCATIONS_FAILURE, { message, error }, undefined, true);

export const setBackOfficeCiboLocations = (enterpriseId: EnterpriseId, locations: any) =>
  action(ActionTypes.SET_BACK_OFFICE_CIBO_LOCATIONS, { enterpriseId, locations });
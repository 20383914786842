import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const DEFAULT_NUMBER_OF_RETRIES = 3;
const DEFAULT_RETRY_DELAY = 2000;

export async function retryAsync<T>(
  toRetry: () => Promise<T>,
  retries: number,
  delay: number
): Promise<T> {
  let attempts = 0;

  while (attempts < retries) {
    try {
      return await toRetry();
    } catch (error) {
      attempts++;

      if (attempts >= retries) {
        throw error;
      }

      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }

  throw new Error('Retry attempts exhausted');
}

// TODO: Disable retry when error is not a timeout https://farmbot.atlassian.net/browse/FMBT-801
export async function getRequest(
  path: string,
  config?: AxiosRequestConfig,
  retryOnError: boolean = true // default to retry on error
): Promise<AxiosResponse> {
  const request: () => Promise<AxiosResponse> = () => axios.get(path, { ...(config || {}) });
  if (retryOnError) {
    return retryAsync(request, DEFAULT_NUMBER_OF_RETRIES, DEFAULT_RETRY_DELAY);
  } else {
    return request();
  }
}

export async function postRequest(path: string, data: unknown): Promise<AxiosResponse> {
  return axios.post(path, data, {});
}

export async function putRequest(path: string, data?: unknown): Promise<AxiosResponse> {
  return axios.put(path, data, {});
}

export async function patchRequest(path: string, data: unknown): Promise<AxiosResponse> {
  return axios.patch(path, data, {});
}

export async function deleteRequest(path: string, data?: unknown): Promise<AxiosResponse> {
  return axios.delete(path, { data });
}
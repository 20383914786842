import axios from 'axios';
import { call } from 'redux-saga/effects';

import { APIResponseParsed } from 'models/response';
import { CiboImagerySet, CiboLocation, CiboLocationRawDataPoint, CiboSubscription } from 'redux/modules/ciboLocations/types';
import { getRequest, nonGeneratorDeleteRequest, nonGeneratorPatchRequest, postRequest } from 'utils/redux-saga-requests';

import { parseAxiosError } from './utils/parse-axios-error';

// ==============================
// TYPES
// ==============================
export type AddCiboLocationDto = Pick<CiboLocation, 'latitude' | 'longitude' | 'enterpriseId' | 'siteId'>;
export interface UpdateCiboLocationDTO {
  name?: CiboLocation['name'];
  latitude?: CiboLocation['latitude'];
  longitude?: CiboLocation['longitude'];
}

// ==============================
// REQUESTS
// ==============================
export function* fetchCiboSubscriptions(enterpriseId: number) {
  try {
    const { data }: { data: CiboSubscription[] } = yield call(
      getRequest,
      `/integrations/cibolabs?enterpriseId=${enterpriseId}`
    );
    return data;
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
  }
}

export function* fetchCiboLocations(enterpriseId: number) {
  try {
    const { data } = yield call(
      getRequest,
      `/integrations/cibolabs/locations?enterpriseId=${enterpriseId}`
    );
    return data as CiboLocation[];
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
  }
}

export function* fetchCiboLocation(locationId: number) {
  try {
    const { data } = yield call(
      getRequest,
      `/integrations/cibolabs/locations/${locationId}`
    );
    return data as CiboLocation;
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
  }
}

export function* addCiboLocation(dto: AddCiboLocationDto) {
  const { latitude, longitude, enterpriseId, siteId } = dto;
  try {
    const { data } = yield call(
      postRequest,
      '/integrations/cibolabs/locations',
      {
        latitude,
        longitude,
        enterpriseId,
        siteId
      }
    );
    return data;
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
  }
}

export function* fetchCiboImageries(enterpriseId: number) {
  try {
    const { data } = yield call(
      getRequest,
      `/integrations/cibolabs/locations/imagery?enterpriseId=${enterpriseId}`
    );
    return data as CiboImagerySet;
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
  }
}

export function* fetchCiboImagery(locationId: number) {
  try {
    const { data } = yield call(
      getRequest,
      `/integrations/cibolabs/locations/${locationId}/imagery/latest`,
      undefined,
      false
    );
    return data;
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
  }
}

export function* fetchCiboLocationStatus(locationId: number) {
  try {
    const { data } = yield call(
      getRequest,
      `/integrations/cibolabs/locations/${locationId}/summary`,
      undefined,
      false
    );
    return data;
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
  }
}

export function* fetchCiboLocationDataPoints(
  locationId: number,
  startDateMs: number,
  endDateMs: number
) {
  try {
    const { data }: { data: CiboLocationRawDataPoint[] } = yield call(
      getRequest,
      `/integrations/cibolabs/locations/${locationId}/data`,
      {
        params: {
          start: startDateMs,
          end: endDateMs
        }
      }
    );
    return data;
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
  }
}

export async function editCiboLocation(
  locationId: number,
  data: UpdateCiboLocationDTO
): Promise<APIResponseParsed<CiboLocation>> {
  try {
    const response = await nonGeneratorPatchRequest<CiboLocation, typeof data>(
      `/integrations/cibolabs/locations/${locationId}`,
      data
    );
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

export async function deleteCiboLocation(
  locationId: number
): Promise<APIResponseParsed<'OK'>> {
  try {
    await nonGeneratorDeleteRequest<{ status: number, message: string }>(
      `/integrations/cibolabs/locations/${locationId}`
    );
    return { data: 'OK', error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}
import { message as antdMessage } from 'antd';
import { AxiosError } from 'axios';
import get from 'lodash/get';
import { all, call, delay, fork, put, select, spawn, takeLatest } from 'redux-saga/effects';

import { ciboLocationClient } from 'clients';
import { CIBO_DATA_AND_IMAGERY_POLLING_INTERVAL_IN_MS } from 'constants/integrations';
import Enterprise from 'models/enterprise';
import { APIResponseParsed } from 'models/response';
import Site from 'models/site';
import { parseCiboLocationRawStatus } from 'utils/CiboLocation/parse-raw-cibo-location-status';
import { Quantity } from 'utils/convert-unit';

import {
  addCiboLocation,
  addCiboLocationFailure,
  addCiboLocationSuccess,
  deleteCiboLocation,
  deleteCiboLocationFailure,
  deleteCiboLocationSuccess,
  editCiboLocation,
  editCiboLocationFailure,
  editCiboLocationSuccess,
  fetchCiboImageries,
  fetchCiboImageriesFailure,
  fetchCiboImageriesSuccess,
  fetchCiboImagery,
  fetchCiboLocation,
  fetchCiboLocationDataPoints,
  fetchCiboLocationDataPointsFailure,
  fetchCiboLocationDataPointsSuccess,
  fetchCiboLocationFailure,
  fetchCiboLocationStatus,
  fetchCiboLocationStatusFailure,
  fetchCiboLocationStatusSuccess,
  fetchCiboLocationSuccess,
  fetchCiboLocations,
  fetchCiboLocationsFailure,
  fetchCiboLocationsSuccess,
  fetchCiboSubscriptions,
  fetchCiboSubscriptionsFailure,
  fetchCiboSubscriptionsSuccess,
  setCiboImageries,
  setCiboImagery,
  setCiboLocation,
  setCiboLocationDataPoints,
  setCiboLocationStatus,
  setCiboLocations,
  setCiboSubscriptionSummary,
  unsetCiboLocation
} from './actions';
import { CiboActionType } from './constants';
import { makeSelectCiboLocationByLocationId } from './selectors';
import { CiboImagerySet, CiboLocation, CiboLocationRawDataPoint, CiboLocationRawStatus, CiboSubscription } from './types';
import { selectCurrentEnterprise, selectCurrentEnterpriseCountry } from '../enterprise/selectors';
import { selectDefaultSite } from '../localUserSettings/selectors';

// ==============================
// Utilities
// ==============================
function parseRawDataPoints(rawDataPoints: CiboLocationRawDataPoint[], locationId: number) {
  return rawDataPoints.map(dp => ({
    id: dp.date,
    locationId,
    date: dp.date,
    tsdm: new Quantity(dp.tsdmKgha, {
      symbol: 'kg/ha'
    }),
    greenMatter: new Quantity(dp.greenMatterKgha, {
      symbol: 'kg/ha'
    }),
    dryMatter: new Quantity(dp.dryMatterKgha, {
      symbol: 'kg/ha'
    })
  }))
    .sort((a, b) => a.date - b.date);
}

// ==============================
// SAGAS
// ==============================
function* requestFetchCiboSubscriptions(action: ReturnType<typeof fetchCiboSubscriptions>) {
  try {
    const { id: enterpriseId } = yield select(selectCurrentEnterprise);
    const subscriptions: CiboSubscription[] = yield ciboLocationClient.fetchCiboSubscriptions(enterpriseId);
    yield put(fetchCiboSubscriptionsSuccess(subscriptions));
    const totalPurchasedLocations = subscriptions
      .filter(sub => sub.status === 'active')
      .reduce((total, sub) => total + sub.numberOfLocations, 0);
    const hasActiveSubscriptions = subscriptions.some(sub => sub.status === 'active');
    const subscriptionSummary = {
      totalPurchasedLocations,
      hasActiveSubscriptions
    };
    yield put(setCiboSubscriptionSummary(subscriptionSummary));
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(fetchCiboSubscriptionsFailure(message, error as AxiosError));
  }
}

function* requestFetchCiboLocations(action: ReturnType<typeof fetchCiboLocations>) {
  try {
    const { id } = yield select(selectCurrentEnterprise);
    const locations: CiboLocation[] = yield ciboLocationClient.fetchCiboLocations(id);
    yield put(fetchCiboLocationsSuccess(locations));
    yield put(setCiboLocations(locations));
    if (locations.length) {
      yield put(fetchCiboImageries());
    } else {
      yield put(setCiboImageries([]));
    }
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(fetchCiboLocationsFailure(message, error as AxiosError));
  }
}

function* requestFetchCiboLocation({
  payload: { locationId }
}: ReturnType<typeof fetchCiboLocation>) {
  try {
    const data: CiboLocation = yield ciboLocationClient.fetchCiboLocation(locationId);
    yield put(fetchCiboLocationSuccess(data));
    yield put(setCiboLocation(data.id, data));
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(fetchCiboLocationFailure(message, error as AxiosError));
  }
}

function* requestFetchCiboImageries(action: ReturnType<typeof fetchCiboImageries>) {
  const { id: enterpriseId } = yield select(selectCurrentEnterprise);
  try {
    const data: CiboImagerySet[] = yield ciboLocationClient.fetchCiboImageries(enterpriseId);
    yield put(fetchCiboImageriesSuccess(data));
    yield put(setCiboImageries(data));
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(fetchCiboImageriesFailure(message, error as AxiosError));
  }
}

function* requestFetchCiboImagery({ payload: { locationId } }: ReturnType<typeof fetchCiboImagery>) {
  const imagerySet = yield ciboLocationClient.fetchCiboImagery(locationId);
  if (imagerySet) {
    yield put(setCiboImagery(imagerySet));
  }
}

function* pollCiboLocationDataAndImagery(locationId: number, delayTime: number) {
  let counter = 0;
  const pollingMaxTimes = 20;
  const pollingInterval = 20_000;
  yield delay(delayTime);
  while (counter < pollingMaxTimes) {
    yield delay(pollingInterval);
    const location: CiboLocation = yield select(makeSelectCiboLocationByLocationId(locationId));
    if (!location) { // The location has been deleted
      break;
    } else if (location.lastDataStatus === 'ready') {
      yield put(fetchCiboImagery(locationId));
      break;
    } else {
      yield put(fetchCiboLocationStatus(locationId));
    }
    counter += 1;
  }
}

function* requestAddCiboLocation({
  payload: { lat, lng }
}: ReturnType<typeof addCiboLocation>) {
  const enterprise: Enterprise = yield select(selectCurrentEnterprise);
  const site: Site = yield select(selectDefaultSite);
  try {
    const location: CiboLocation = yield ciboLocationClient.addCiboLocation({
      latitude: lat,
      longitude: lng,
      enterpriseId: enterprise.id,
      siteId: site.id
    });
    yield put(addCiboLocationSuccess(location));
    yield put(setCiboLocation(location.id, location));
    yield spawn(pollCiboLocationDataAndImagery, location.id, CIBO_DATA_AND_IMAGERY_POLLING_INTERVAL_IN_MS);
  } catch (error) {
    antdMessage.error('Failed to place the Pasture Zone on the map');
    const message = get(
      error,
      'response.data.message',
      'Failed to place the Pasture Zone on the map'
    );
    yield put(addCiboLocationFailure(message, error as AxiosError));
  }
}

function* requestCiboLocationStatus({
  payload: { locationId }
}: ReturnType<typeof fetchCiboLocationStatus>) {
  const country = yield select(selectCurrentEnterpriseCountry);
  try {
    const rawStatus: CiboLocationRawStatus = yield ciboLocationClient.fetchCiboLocationStatus(locationId);
    const status = parseCiboLocationRawStatus(rawStatus, country);
    yield put(fetchCiboLocationStatusSuccess(locationId, status));
    yield put(setCiboLocationStatus(locationId, status));
    yield put(setCiboLocation(
      locationId,
      {
        lastDataStatus: rawStatus.lastDataStatus,
        lastDataDate: rawStatus.lastDataDate
      }));
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(
      fetchCiboLocationStatusFailure(
        message,
        error as AxiosError
      )
    );
  }
}

function* requestFetchCiboLocationDataPoints({
  payload: { locationId, startDateMs, endDateMs }
}: ReturnType<typeof fetchCiboLocationDataPoints>) {
  try {
    const rawDataPoints: CiboLocationRawDataPoint[]
      = yield ciboLocationClient.fetchCiboLocationDataPoints(locationId, startDateMs, endDateMs);
    const dataPoints = parseRawDataPoints(rawDataPoints, locationId);
    yield put(fetchCiboLocationDataPointsSuccess(dataPoints));
    yield put(setCiboLocationDataPoints(dataPoints));
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(
      fetchCiboLocationDataPointsFailure(
        message,
        error as AxiosError
      )
    );
  }
}

function* requestEditCiboLocation({
  payload: { locationId, data }
}: ReturnType<typeof editCiboLocation>) {
  const isUpdatingCoordinates = data.latitude && data.longitude;
  if (isUpdatingCoordinates) {
    // To remove the old imagery and update the center of the updated location before the call of drawCiboLocations()
    // and before the response is received. It creates the correct placeholder on the map.
    yield put(setCiboLocation(locationId, {
      imagerySet: undefined,
      status: undefined,
      latitude: data.latitude,
      longitude: data.longitude
    }));
  }

  const response: APIResponseParsed<CiboLocation> = yield call(ciboLocationClient.editCiboLocation, locationId, data);
  if (response.data) {
    yield put(editCiboLocationSuccess());
    yield put(fetchCiboLocation(locationId));
    if (isUpdatingCoordinates) {
      yield spawn(pollCiboLocationDataAndImagery, locationId, CIBO_DATA_AND_IMAGERY_POLLING_INTERVAL_IN_MS);
    }
  } else {
    const message = response.error.message || 'Failed to edit Pasture Zone';
    yield put(editCiboLocationFailure(message));
    antdMessage.error(message);
  }
}

function* requestDeleteCiboLocation({ payload: { locationId } }: ReturnType<typeof deleteCiboLocation>) {
  const response: APIResponseParsed<'OK'> = yield call(ciboLocationClient.deleteCiboLocation, locationId);
  if (response.data) {
    yield put(deleteCiboLocationSuccess());
    yield put(unsetCiboLocation(locationId));
    antdMessage.success('Pasture Zone deleted successfully');
  } else {
    const message = response.error.message || 'Failed to delete Pasture Zone';
    yield put(deleteCiboLocationFailure(message));
    antdMessage.error(message);
  }
}

// ==============================
// REGISTRATION
// ==============================
function* watchFetchCiboSubscriptionsRequest() {
  yield takeLatest(CiboActionType.FETCH_CIBO_SUBSCRIPTIONS_REQUEST, requestFetchCiboSubscriptions);
}

function* watchFetchCiboLocationsRequest() {
  yield takeLatest(CiboActionType.FETCH_CIBO_LOCATIONS_REQUEST, requestFetchCiboLocations);
}

function* watchFetchCiboLocationRequest() {
  yield takeLatest(CiboActionType.FETCH_CIBO_LOCATION_REQUEST, requestFetchCiboLocation);
}

function* watchAddCiboLocationRequest() {
  yield takeLatest(CiboActionType.ADD_CIBO_LOCATION_REQUEST, requestAddCiboLocation);
}

function* watchFetchCiboImageryRequest() {
  yield takeLatest(CiboActionType.FETCH_CIBO_IMAGERY_REQUEST, requestFetchCiboImagery);
};

function* watchFetchCiboImageriesRequest() {
  yield takeLatest(
    CiboActionType.FETCH_CIBO_IMAGERIES_REQUEST,
    requestFetchCiboImageries
  );
}

function* watchFetchCiboLocationStatusRequest() {
  yield takeLatest(
    CiboActionType.FETCH_CIBO_LOCATION_STATUS_REQUEST,
    requestCiboLocationStatus
  );
}

function* watchFetchCiboLocationsDataPointsRequest() {
  yield takeLatest(
    CiboActionType.FETCH_CIBO_LOCATION_DATA_POINTS_REQUEST,
    requestFetchCiboLocationDataPoints
  );
}

function* watchEditCiboLocationRequest() {
  yield takeLatest(CiboActionType.EDIT_CIBO_LOCATION_REQUEST, requestEditCiboLocation);
}

function* watchDeleteCiboLocationRequest() {
  yield takeLatest(CiboActionType.DELETE_CIBO_LOCATION_REQUEST, requestDeleteCiboLocation);
}

export default function* ciboLocationsSaga() {
  yield all([
    fork(watchFetchCiboSubscriptionsRequest),
    fork(watchFetchCiboLocationsRequest),
    fork(watchAddCiboLocationRequest),
    fork(watchFetchCiboLocationRequest),
    fork(watchFetchCiboImageryRequest),
    fork(watchFetchCiboImageriesRequest),
    fork(watchFetchCiboLocationStatusRequest),
    fork(watchFetchCiboLocationsDataPointsRequest),
    fork(watchEditCiboLocationRequest),
    fork(watchDeleteCiboLocationRequest)
  ]);
}

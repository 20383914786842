import axios, { AxiosRequestConfig } from 'axios';
import type { SagaIterator } from 'redux-saga';
import { call, retry } from 'redux-saga/effects';

/**
 * Makes a get request with the API domain name attached to the path.
 * And with the Authorization header included.
 */
export function* getRequest(
  path: string,
  config?: AxiosRequestConfig,
  retryOnError = true
): SagaIterator {
  // TODO: Only retry if it's a network error. https://farmbot.atlassian.net/browse/MFB-353
  // Attempting to make the request up to 3 times help to mitigate network errors caused by a bad internet connection
  return yield retry(retryOnError ? 3 : 1, 2000, axios.get, path, {
    ...(config || {})
  });
}

export function* postRequest(path: string, data: unknown): SagaIterator {
  return yield call(axios.post, path, data, {});
}

export function* putRequest(path: string, data?: unknown): SagaIterator {
  return yield call(axios.put, path, data, {});
}

export function* patchRequest(path: string, data: unknown): SagaIterator {
  return yield call(axios.patch, path, data, {});
}

export function* deleteRequest(path: string, data?: unknown): SagaIterator {
  return yield call(axios.delete, path, { data });
}

/** TODO: rename this to remove the 'nonGenerator' prefix after we are able to remove the legacy calls */
export function nonGeneratorPatchRequest<T, D>(path: string, data: D) {
  return axios.patch<T>(path, data);
}

/** TODO: rename this to remove the 'nonGenerator' prefix after we are able to remove the legacy calls */
export function nonGeneratorDeleteRequest<T>(path: string) {
  return axios.delete<T>(path);
}
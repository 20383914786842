import { message as antdMessage } from 'antd';
import axios, { AxiosError } from 'axios';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import { all, fork, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';

import { ipCameraClient } from 'clients';
import IPCamera, { IPCameraCapabilities, IPCameraPhoto, PTZPreset } from 'models/ipCamera';
import { PaginatedResponse } from 'models/response';
import { EntityKind } from 'models/userPreference';
import { unsetMyPreference } from 'redux/modules/myPreferences/actions';
import { removeLastSubPath } from 'redux/modules/routerUtils/actions';

import {
  activateIPCameraPTZPresetFailure,
  activateIPCameraPTZPresetRequest,
  activateIPCameraPTZPresetSuccess,
  appendIPCameraEvents,
  createIPCameraPTZPresetFailure,
  createIPCameraPTZPresetRequest,
  createIPCameraPTZPresetSuccess,
  deleteIPCameraPTZPresetFailure,
  deleteIPCameraPTZPresetRequest,
  deleteIPCameraPTZPresetSuccess,
  editIPCamera,
  editIPCameraFailure,
  editIPCameraSuccess,
  fetchCameraById,
  fetchCameraByIdSuccess,
  fetchCamerasBySite,
  fetchCamerasBySiteFailure,
  fetchCamerasBySiteSuccess,
  fetchIPCameraCapabilities,
  fetchIPCameraCapabilitiesFailure,
  fetchIPCameraCapabilitiesSuccess,
  fetchIPCameraEvents,
  fetchIPCameraEventsFailure,
  fetchIPCameraEventsSuccess,
  fetchIPCameraPTZPresetsFailure,
  fetchIPCameraPTZPresetsRequest,
  fetchIPCameraPTZPresetsSuccess,
  fetchIPCameraPhotos,
  fetchIPCameraPhotosFailure,
  fetchIPCameraPhotosSuccess,
  fetchIPCameraState,
  fetchIPCameraStateFailure,
  fetchIPCameraStateSuccess,
  removeFromIPCameraList,
  removeIPCamera,
  removeIPCameraPTZPreset,
  removeIPCameraSuccess,
  resetIPCameraUnseenPhoto,
  sendCameraPTZControlRequest,
  setIPCameraCapabilities,
  setIPCameraEvents,
  setIPCameraPTZPreset,
  setIPCameraPTZPresets,
  setIPCameraPhotos,
  setIPCameraPreview,
  setIPCameraPreviewError,
  setIPCameraState,
  setIPCameraUnseenPhoto,
  takeIPCameraPhoto,
  takeIPCameraPhotoFailure,
  takeIPCameraPhotoSuccess,
  takeIPCameraPreview,
  takeIPCameraPreviewFailure,
  takeIPCameraPreviewSuccess,
  updateIPCameraPTZPresetFailure,
  updateIPCameraPTZPresetRequest,
  updateIPCameraPTZPresetSuccess
} from './actions';
import ActionTypes from './constants';
import { selectIPCamera } from './selector';
import { IPCamerasState } from './types';

// ==============================
// SAGAS
// ==============================
function* requestIPCamerasBySite(action: ReturnType<typeof fetchCamerasBySite>) {
  const { payload: { enterpriseId, siteId } } = action;

  try {
    const { data } = yield ipCameraClient.fetchIPCamerasBySite(enterpriseId, siteId);

    // TODO: create proper mapping object; introduce DTOs for FE
    const ipCameras: IPCamerasState = keyBy(data, 'id');

    yield put(fetchCamerasBySiteSuccess(ipCameras));
  } catch (error) {
    yield put(fetchCamerasBySiteFailure('Failed to retrieve Security Cameras'));
  }
}

function* requestIPCameraById(action: ReturnType<typeof fetchCameraById>) {
  const { payload: { id } } = action;

  try {
    const { data } = yield ipCameraClient.fetchIPCameraById(id);
    const { data: metadata } = yield ipCameraClient.fetchIPCameraONVIFStateById(id);

    const ipCamera = {
      ...data,
      metadata
    };

    yield put(fetchCameraByIdSuccess(ipCamera));
  } catch (error) {
    antdMessage.error('Failed to retrieve Security Camera');
  }
}

function* requestTakeIPCameraPreview(action: ReturnType<typeof takeIPCameraPreview>) {
  const { payload: { ipCameraId } } = action;

  try {
    const response = yield ipCameraClient.takeIPCameraPreview(ipCameraId);
    yield put(takeIPCameraPreviewSuccess(response));

    const latestPreview = response.data;
    yield put(setIPCameraPreview(ipCameraId, latestPreview));

  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      'Failed to take a preview'
    );
    yield put(takeIPCameraPreviewFailure(message, error));
    yield put(setIPCameraPreviewError(ipCameraId, message));
  }
}

function* requestFetchIPCameraState(action: ReturnType<typeof fetchIPCameraState>) {
  const { payload: { ipCameraId } } = action;

  try {
    const response = yield ipCameraClient.fetchIPCameraONVIFStateById(ipCameraId);
    yield put(fetchIPCameraStateSuccess(response));

    const state = response.data;
    yield put(setIPCameraState(ipCameraId, state));

  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      'Failed to retrieve Security Camera state'
    );
    yield put(fetchIPCameraStateFailure(message, error));

    const unknownState = { status: { online: false }, capabilities: { ptz: false } };
    yield put(setIPCameraState(ipCameraId, { ...unknownState, error: message }));
  }
}

function* requestFetchIPCameraCapabilities(action: ReturnType<typeof fetchIPCameraCapabilities>) {
  const { payload: { ipCameraId, capabilityFields } } = action;

  try {
    const response = yield ipCameraClient.fetchIPCameraCapabilities(ipCameraId, capabilityFields);
    yield put(fetchIPCameraCapabilitiesSuccess(response));

    const capabilities: Partial<IPCameraCapabilities> = response.data;
    yield put(setIPCameraCapabilities(ipCameraId, capabilities));

  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      'Failed to retrieve Security Camera capabilities'
    );
    yield put(fetchIPCameraCapabilitiesFailure(message, error));
  }
}

function* requestFetchIPCameraPhotos(action: ReturnType<typeof fetchIPCameraPhotos>) {
  const { payload: { ipCameraId, params } } = action;

  try {
    const response = yield ipCameraClient.fetchIPCameraPhotos(ipCameraId, params);
    yield put(fetchIPCameraPhotosSuccess(response));

    const { count, data: photos } = response.data as PaginatedResponse<IPCameraPhoto>;
    yield put(setIPCameraPhotos(ipCameraId, { currentPage: params.page, currentData: photos, totalDataItems: count }));

    yield put(resetIPCameraUnseenPhoto(ipCameraId));

  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(fetchIPCameraPhotosFailure(message, error));
  }
}

function* requestTakeIPCameraPhoto(action: ReturnType<typeof takeIPCameraPhoto>) {
  const { payload: { ipCameraId } } = action;

  try {
    const response = yield ipCameraClient.takeIPCameraSnapshot(ipCameraId);
    yield put(takeIPCameraPhotoSuccess(response));

    const newPhoto = response.data;
    yield put(setIPCameraUnseenPhoto(ipCameraId, newPhoto));

    antdMessage.success('Photo taken');

  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    antdMessage.error('Failed to take photo');
    yield put(takeIPCameraPhotoFailure(message, error));
  }
}

function* requestIPCameraPTZControl(action: ReturnType<typeof sendCameraPTZControlRequest>) {
  const { payload: { id, control } } = action;

  try {
    yield ipCameraClient.sendIPCameraControl(id, control);
  } catch (error) {
    antdMessage.error('Unable to control Security Camera PTZ');
  }
}

function* requestIPCameraEvents(action: ReturnType<typeof fetchIPCameraEvents>) {
  const { payload: { ipCameraId, startDate, endDate, isAppend } } = action;

  try {
    const { data } = yield ipCameraClient.fetchIPCameraEvents(ipCameraId, startDate, endDate);

    if (data.length > 0) {
      if (isAppend) {
        yield put(appendIPCameraEvents(ipCameraId, data));
      } else {
        yield put(setIPCameraEvents(ipCameraId, data));
      }
    } else if (data.length === 0) {
      if (isAppend) {
        antdMessage.info('No more recordings in this date range');
      } else {
        antdMessage.info('No recordings found');

        // This helps initialise the eventList in DetailsPageContext, to distinguish between
        // no requests made vs. no events found
        yield put(setIPCameraEvents(ipCameraId, []));
      }
    }

    yield put(fetchIPCameraEventsSuccess());
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Failed to retrieve Security Camera recordings'
    );
    yield put(fetchIPCameraEventsFailure(message, error as AxiosError));
    antdMessage.error('Failed to retrieve recordings');
  }
}

function* requestEditIPCamera(action: ReturnType<typeof editIPCamera>) {
  const { payload: { id, values } } = action;

  try {
    const response = yield ipCameraClient.editIPCamera(id, values);
    yield put(editIPCameraSuccess(response));

    const updatedIPCamera = response.data;
    // Repurpose the following action to save the updated Security Camera to the store
    yield put(fetchCameraByIdSuccess(updatedIPCamera));

    antdMessage.success('Security Camera updated');
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    antdMessage.error('Failed to update Security Camera');
    yield put(editIPCameraFailure(message, error));
  }
}

function* requestRemoveIPCamera(action: ReturnType<typeof removeIPCamera>) {
  const { payload: { id } } = action;

  try {
    const response = yield ipCameraClient.deleteIPCamera(id);
    yield put(removeIPCameraSuccess(response));

    yield put(removeLastSubPath());
    yield put(removeFromIPCameraList(id));
    yield put(unsetMyPreference(EntityKind.IP_CAMERA, id));

    antdMessage.success('Security Camera removed');
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong'
    );
    antdMessage.error('Failed to remove Security Camera');
    yield put(editIPCameraFailure(message, error));
  }
}

function* requestFetchIPCameraPTZPresets(action: ReturnType<typeof fetchIPCameraPTZPresetsRequest>) {
  const { payload: { ipCameraId } } = action;

  try {
    const ipCamera: IPCamera = yield select(selectIPCamera(ipCameraId));
    const enterpriseId = ipCamera.landwatchIntegration?.enterpriseId;

    if (!enterpriseId) throw new Error('Missing enterpriseId from ipCamera');

    const response = yield ipCameraClient.fetchIPCameraPTZPresets(enterpriseId, ipCameraId);
    yield put(fetchIPCameraPTZPresetsSuccess(response));

    const presets: PTZPreset[] = response.data;

    yield put(setIPCameraPTZPresets(ipCameraId, presets));
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Failed to retrieve IP Camera PTZ Presets'
    );
    antdMessage.error('Failed to get presets');
    yield put(fetchIPCameraPTZPresetsFailure(message, error));
  }
}

function* requestCreateIPCameraPTZPreset(action: ReturnType<typeof createIPCameraPTZPresetRequest>) {
  const { payload: { ipCameraId } } = action;

  try {
    const response = yield ipCameraClient.createIPCameraPTZPreset(ipCameraId);
    yield put(createIPCameraPTZPresetSuccess(response));

    const preset: PTZPreset = response.data;
    antdMessage.success('Preset created');
    yield put(setIPCameraPTZPreset(ipCameraId, preset));
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Failed to create IP Camera PTZ Preset'
    );
    antdMessage.error('Failed to create preset');
    yield put(createIPCameraPTZPresetFailure(message, error));
  }
}

function* requestUpdateIPCameraPTZPreset(action: ReturnType<typeof updateIPCameraPTZPresetRequest>) {
  const { payload: { ipCameraId, presetId, values } } = action;

  try {
    const response = yield ipCameraClient.updateIPCameraPTZPreset(ipCameraId, presetId, values);
    yield put(updateIPCameraPTZPresetSuccess(response));

    const preset: PTZPreset = response.data;
    antdMessage.success('Preset updated');
    yield put(setIPCameraPTZPreset(ipCameraId, preset));
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Failed to update IP Camera PTZ Preset'
    );
    antdMessage.error('Failed to update preset');
    yield put(updateIPCameraPTZPresetFailure(message, error));
  }
}

function* requestDeleteIPCameraPTZPreset(action: ReturnType<typeof deleteIPCameraPTZPresetRequest>) {
  const { payload: { ipCameraId, presetId } } = action;

  try {
    const response = yield ipCameraClient.deleteIPCameraPTZPreset(ipCameraId, presetId);
    yield put(deleteIPCameraPTZPresetSuccess(response));
    antdMessage.success('Preset deleted');
    yield put(removeIPCameraPTZPreset(ipCameraId, presetId));
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Failed to delete IP Camera PTZ Preset'
    );
    antdMessage.error('Failed to delete preset');
    yield put(deleteIPCameraPTZPresetFailure(message, error));
  }
}

function* requestActivatePTZRequest(action: ReturnType<typeof activateIPCameraPTZPresetRequest>) {
  const { payload: { ipCameraId, presetId } } = action;

  try {
    const response = yield ipCameraClient.activateIPCameraPTZPreset(ipCameraId, presetId);
    yield put(activateIPCameraPTZPresetSuccess(response));
    antdMessage.success('Preset activated');
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Failed to set IP Camera PTZ Preset'
    );
    antdMessage.error('Failed to activate preset');
    yield put(activateIPCameraPTZPresetFailure(message, error));
  }
}

// ==============================
// REGISTRATION
// ==============================
function* watchIPCamerasListBySiteRequest() {
  yield takeLatest(ActionTypes.FETCH_CAMERAS_BY_SITE_REQUEST, requestIPCamerasBySite);
}

function* watchIPCameraByIdRequest() {
  yield takeLatest(ActionTypes.FETCH_CAMERA_BY_ID_REQUEST, requestIPCameraById);
}

function* watchTakeIPCameraPreviewRequest() {
  yield takeEvery(ActionTypes.TAKE_IP_CAMERA_PREVIEW_REQUEST, requestTakeIPCameraPreview);
}

function* watchFetchIPCameraStateRequest() {
  yield takeEvery(ActionTypes.FETCH_IP_CAMERA_STATE_REQUEST, requestFetchIPCameraState);
}

function* watchFetchIPCameraCapabilitiesRequest() {
  yield takeLatest(ActionTypes.FETCH_IP_CAMERA_CAPABILITIES_REQUEST, requestFetchIPCameraCapabilities);
}

function* watchFetchIPCameraPhotosRequest() {
  yield takeLatest(ActionTypes.FETCH_IP_CAMERA_PHOTOS_REQUEST, requestFetchIPCameraPhotos);
}

function* watchTakeIPCameraPhotoRequest() {
  yield takeLatest(ActionTypes.TAKE_IP_CAMERA_PHOTO_REQUEST, requestTakeIPCameraPhoto);
}

function* watchIPCameraPTZControlRequest() {
  yield takeLeading(ActionTypes.SEND_CAMERA_PTZ_CONTROL_REQUEST, requestIPCameraPTZControl);
}

function* watchFetchIPCameraEventsRequest() {
  yield takeLatest(ActionTypes.FETCH_IP_CAMERA_EVENTS_REQUEST, requestIPCameraEvents);
}

function* watchEditIPCameraRequest() {
  yield takeLatest(ActionTypes.EDIT_IP_CAMERA_REQUEST, requestEditIPCamera);
}

function* watchRemoveIPCameraRequest() {
  yield takeLatest(ActionTypes.REMOVE_IP_CAMERA_REQUEST, requestRemoveIPCamera);
}

function* watchFetchIPCameraPTZPresetsRequest() {
  yield takeLatest(ActionTypes.FETCH_IP_CAMERA_PTZ_PRESETS_REQUEST, requestFetchIPCameraPTZPresets);
}

function* watchCreateIPCameraPTZPresetRequest() {
  yield takeLatest(ActionTypes.CREATE_IP_CAMERA_PTZ_PRESET_REQUEST, requestCreateIPCameraPTZPreset);
}

function* watchUpdateIPCameraPTZPresetRequest() {
  yield takeLatest(ActionTypes.UPDATE_IP_CAMERA_PTZ_PRESET_REQUEST, requestUpdateIPCameraPTZPreset);
}

function* watchDeleteIPCameraPTZPresetRequest() {
  yield takeLatest(ActionTypes.DELETE_IP_CAMERA_PTZ_PRESET_REQUEST, requestDeleteIPCameraPTZPreset);
}

function* watchActivateIPCameraPTZPresetRequest() {
  yield takeLatest(ActionTypes.ACTIVATE_IP_CAMERA_PTZ_PRESET_REQUEST, requestActivatePTZRequest);
}

// ==============================
// EXPORT
// ==============================
export default function* ipCamerasSaga() {
  yield all([
    fork(watchIPCamerasListBySiteRequest),
    fork(watchIPCameraByIdRequest),
    fork(watchTakeIPCameraPreviewRequest),
    fork(watchFetchIPCameraStateRequest),
    fork(watchFetchIPCameraCapabilitiesRequest),
    fork(watchFetchIPCameraPhotosRequest),
    fork(watchTakeIPCameraPhotoRequest),
    fork(watchIPCameraPTZControlRequest),
    fork(watchFetchIPCameraEventsRequest),
    fork(watchEditIPCameraRequest),
    fork(watchRemoveIPCameraRequest),
    fork(watchFetchIPCameraPTZPresetsRequest),
    fork(watchCreateIPCameraPTZPresetRequest),
    fork(watchUpdateIPCameraPTZPresetRequest),
    fork(watchDeleteIPCameraPTZPresetRequest),
    fork(watchActivateIPCameraPTZPresetRequest)
  ]);
}